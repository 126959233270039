import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import DatabaseContext from './DatabaseContext'

const PrivateRoute = ({children}) => {
  const { formData } = useContext(DatabaseContext)

  return formData ? children : <Navigate to = '/' />

  
}

export default PrivateRoute