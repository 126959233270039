import {gsap} from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import React, { useEffect, useRef } from 'react'
import image4 from '../../image/IMG_4268-min.gif'

const Access = () => {
  const access = useRef()
  

  gsap.registerPlugin(ScrollTrigger)
  useEffect(() => {
    var ctx = gsap.context(() => {
      var mn = gsap.matchMedia()

      mn.add('(max-width: 1024px)', () => {
        gsap.set('.access-image', { zIndex: '10'})
        ScrollTrigger.create({
          trigger: '.access-texts',
          start: 'top top',
          pin: true,
          pinSpacing: false,
          scrub: true
        })
      })
      
    }, access)

    return () => ctx.revert()
  }, [])

  
  return (
    <div className='access' ref={access}>
      <div className='access-image wrap'>
        <img src={image4} alt='' />
      </div>
      <div className='access-texts wrap'>
        <h2>Easy Access to the world of web3</h2>
        <div className='access-paragraph'>
          <p className='p1'>With Stallet, non-crypto savvy users, as well as mid-savvy and crypto-savvy can now easily onboard into web3.</p>

          <p className='p2'>With our unique onboarding process and easy approach, you can now start your seamless journey to web3 and unlock its full potentials.</p>

          <p className='p3'>Now with Stallet, <span>onboarding into web3 is bliss.</span> 😃</p>
        </div>
      </div>
    </div>
  )
}

export default Access