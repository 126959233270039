import featuredimage2 from '../../image/Featured icon (1).png'
import featuredimage1 from '../../image/Featured icon (2).png'
import featuredimage3 from '../../image/Featured icon.png'
import image3 from '../../image/image 3.png'
import React from 'react'


const Crypto = () => {


  return (
    <div className='crypto'>
      <div className='container'>
        <div className='crypto-heading'>
          <h2>crypto made easy</h2>
          <p>From swapping tokens, to importing your NFTs, each screen is specifically designed to enhance your ease of use.</p>
        </div>
      </div>
      <div className='crypto-container'>
        <div className='crypto-details'>
          <div className='crypto-image'>
            <img src={image3} alt='' />
          </div>
          <div className='crypto-features'>
            <div className='crypto-features-container feature1'>
              <div className='crypto-features-image'>
                <img src={featuredimage1} alt='' />
              </div>
              <div className='crypto-features-texts'>
                <h3>Send token with ease</h3>
                <p>With our simplified approach, users can now send and receive tokens with ease.</p>
              </div>
            </div>
            <div className='crypto-features-container feature2'>
              <div className='crypto-features-image'>
                <img src={featuredimage2} alt='' />
              </div>
              <div className='crypto-features-texts'>
                <h3>Swap and Bridge tokens with low fees</h3>
                <p>Move tokens from different chains, and swap tokens with no fiat currency involved.</p>
              </div>
            </div>
            <div className='crypto-features-container feature3'>
              <div className='crypto-features-image'>
                <img src={featuredimage3} alt='' />
              </div>
              <div className='crypto-features-texts'>
                <h3>Send, receive and manage NFTs</h3>
                <p>With Stallet, you can send, receive, and manage your NFTs with just one click.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Crypto