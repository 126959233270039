import { BsArrowUpRight, BsTwitter, BsLinkedin, BsInstagram, BsFacebook, BsDiscord, BsMedium } from 'react-icons/bs'
import { GoMail } from 'react-icons/go'
import React, { useContext, useEffect, useRef, useState } from 'react'
import logo from './image/Layer 1.png'
import { Link } from 'react-router-dom'
import DatabaseContext from './DatabaseContext'
import gsap, { Power3 } from 'gsap'
import logos from './image/stalletLogo-alt-removebg-preview.png'
import Lottie from 'lottie-react'
import confetti from './confetti.json'

const Confirmation = () => {
  const { getEmail, formData } = useContext(DatabaseContext)
  const [newUser, setNewUser] = useState({})
  
  const confirmation = useRef()
  useEffect(() => {
      gsap.to('.transition > img', {
        duration: 1,
        scale: '3',
        ease: Power3.easeInOut
      })
      gsap.to('.transition', {
        duration: 1.5,
        left: '200%',
        ease: Power3.easeInOut,
        // opacity: 0,
        delay: .3
      })
      gsap.to('.confetti', {
        duration: 4,
        // left: '200%',
        display: 'none',
        ease: Power3.easeInOut,
        // opacity: 0,
        delay: 1.5
      })
  }, [])
  
  useEffect(() => {
    async function fetchData(){

      const querySnapshot = await getEmail()
      querySnapshot.forEach((doc ) => {
        if(doc.id === formData.email){
          setNewUser(doc.data()) 
        }
      })
    }
    
    
    fetchData()
    
    
  }, [formData.email, getEmail])

  return (
    <main className='confirm-main' ref={confirmation}>
      <div className='transition'>
        <img src={logos} alt='' />
      </div>
      <div className='confetti'>
        <Lottie animationData={confetti} />
      </div>
      <div className='confirm'>
        <div className='container confirm-container'>
          <div className='confirm-header'>
            <Link to='/'>
              <img src={logo} alt='' />
            </Link>
            <div className='confirm-mail'>
              <p>{newUser.email}</p>
              <Link to='/' className='confirm-mail-link'>Not you?</Link>
            </div>
          </div>
          <div className='confirm-body'>
            <div className='confirm-body-1'>
              <h1>HODL tight.<br/> Thank you for joining our waitlist, you will get notified when we launch.</h1>
              <p>Your all-in-one cross-chain and non-custodial mobile crypto wallet built on Stacks blockchain, secured by Bitcoin.</p>
            </div>
            <div className='confirm-body-2'>
              <div className='confirm-body-mail'>
                <p>Have any questions?</p>
                <a href='mailto:hello@stallet.co'>hello@stallet.co <span><BsArrowUpRight /></span></a>
              </div>
              <p>Coming soon!!! <span>😃</span></p>
            </div>
          </div>
        </div>
      </div>
      <div className='container confirm-footer'>
          <div className='footer-socials'>
            <p>© 2022 Stallet Inc. All rights reserved.</p>
            <ul className='footer-links'>
              <li><a href='/'><BsTwitter /></a></li>
              <li><a href='/'><BsLinkedin /></a></li>
              <li><a href='/'><BsInstagram /></a></li>
              <li><a href='/'><BsFacebook /></a></li>
              <li><a href='/'><BsDiscord /></a></li>
              <li><a href='/'><BsMedium /></a></li>
              <li><a href='/'><GoMail /></a></li>
            </ul>
          </div>
        </div>
    </main>
  )
}

export default Confirmation