import React, { useEffect, useRef } from 'react'
import Hero from './Hero'
import Crypto from './Crypto'
import Access from './Access'
import Web3 from './Web3'
import '../../styles.css'
import ScrollTrigger from 'gsap/ScrollTrigger'
import {gsap} from 'gsap'

const Main = () => {
  const main = useRef()
  

  gsap.registerPlugin(ScrollTrigger)
  useEffect(() => {
    var ctx = gsap.context(() => {
      var mn = gsap.matchMedia()
      

      mn.add('(min-width: 1024px)', () => {
        gsap.utils.toArray('.wrap').forEach((container, i) => {
          ScrollTrigger.create({
            trigger: container,
            start: 'top top',
            end: 'bottom top',
            pin: true,
            pinSpacing: false,
            // snap: 1
          })
        })
      })

      mn.add('(max-width: 1023px)', () => {
        ScrollTrigger.create({
          trigger: '.container1',
          start: 'top top',
          end: 'bottom top',
          // scrub: true,
          pin: true,
          pinSpacing: false,
        })
      })
      // gsap.set('.container1', {display: 'block'})
     
    }, main)

    return () => ctx.revert()
  }, [])

  return (
    <main ref={main}>
     <div className='wrapper'>
        <div className='wrap container1'><Hero /></div>
        <div className='wrap container2'><Access /></div>
        <div className=' container3'><Crypto /></div>
        <div className='wrap container4'><Web3 /></div>
     </div>
    </main>
  )
}

export default Main


