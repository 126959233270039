import React, { useContext, useState } from 'react'
import logo from '../image/Layer 1.png'
import { NotificationStatus } from 'iconsax-react'
import '../styles.css'
import frame1 from '../image/frame1.png'
import frame2 from '../image/frame2.png'
import frame3 from '../image/frame3.png'
import frame4 from '../image/frame4.png'
import frame5 from '../image/frame5.png'
import frame6 from '../image/frame6.png'
import frame7 from '../image/frame7.png'
import frame8 from '../image/frame8.png'
import frame9 from '../image/frame9.png'
import sms from '../image/sms-notification.png'
import { Link, useNavigate } from 'react-router-dom'
import DatabaseContext from '../DatabaseContext'

const Header = () => {
  const {formData, handleFormData, addEmail, refresh} = useContext(DatabaseContext)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [details, setDetails] = useState(false)
  const Navigate = useNavigate()

  const handleDetails = () => {
    setDetails(state => !state)
  }

  async function handleSubmit(e) {
    e.preventDefault()
    try{
      setError('')
      setLoading(true)
      formData.count++
      await addEmail()
      Navigate('/confirmation')
      refresh()
    }
    catch(err){
      setLoading(false)
      setError(`${err}`)
      alert(error)
      // console.log(err)
    }
  }

  return (
    <nav className='nav'>
       
      <div className='container nav-container'>
        <Link to='/' className='nav-image'>
          <img src={logo} alt='' />
        </Link>
        <button className='btn' onClick={handleDetails}>
          <span>
            <NotificationStatus
              size="16"
              color="#FFF"
            />
          </span>
          Join Waitlist
        </button>
        <div className={details ? 'nav-details' : 'hide-details'}>
          <div className='nav-texts'>
            <h1>Get notified <span className='nav-span'><img src={sms} alt='' /></span> when we launch</h1>
            <div className='nav-images'>
              <img src={frame1} alt='' />
              <img src={frame2} alt='' />
              <img src={frame3} alt='' />
              <img src={frame4} alt='' />
              <img src={frame5} alt='' />
              <img src={frame6} alt='' />
              <img src={frame7} alt='' />
              <img src={frame8} alt='' />
              <img src={frame9} alt='' />
            </div>
          </div>
          <form className='nav-form' onSubmit={handleSubmit}>
            <input type='email' name='email' value={formData.email} onChange={handleFormData} placeholder='Kindly enter your email address' />
            <button disabled={loading} className='btn hero-btn'>
              <NotificationStatus
                size="16"
                color="#FFF"
              />
              {loading ? 'Loading...' : 'Join Waitlist'}
            </button>
          </form>
        </div>
      </div>
    </nav>
  )
}

export default Header