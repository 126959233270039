import { NotificationStatus} from 'iconsax-react'
import frame1 from '../image/frame1.png'
import frame2 from '../image/frame2.png'
import frame3 from '../image/frame3.png'
import frame4 from '../image/frame4.png'
import frame5 from '../image/frame5.png'
import frame6 from '../image/frame6.png'
import frame7 from '../image/frame7.png'
import frame8 from '../image/frame8.png'
import frame9 from '../image/frame9.png'
import sms from '../image/sms-notification.png'
import { BsTwitter, BsLinkedin, BsInstagram, BsFacebook, BsDiscord, BsMedium } from 'react-icons/bs'
import { GoMail } from 'react-icons/go'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DatabaseContext from '../DatabaseContext'

const Footer = () => {
  const {formData, handleFormData, addEmail, refresh} = useContext(DatabaseContext)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const Navigate = useNavigate()



  async function handleSubmit(e) {
    e.preventDefault()
    try{
      setError('')
      setLoading(true)
      formData.count++
      await addEmail()
      Navigate('/confirmation')
      refresh()
    }
    catch(err){
      setLoading(false)
      setError(`${err}`)
      alert(error)
      // console.log(err)
    }
  }

  return (
    <footer className='footer'>
      <div className='container footer-container'>
        <div className='footer-details'>
          <div className='footer-texts'>
            <h1>Get notified <span className='footer-span'><img src={sms} alt='' /></span> when we launch</h1>
            <div className='footer-images'>
              <img src={frame1} alt='' />
              <img src={frame2} alt='' />
              <img src={frame3} alt='' />
              <img src={frame4} alt='' />
              <img src={frame5} alt='' />
              <img src={frame6} alt='' />
              <img src={frame7} alt='' />
              <img src={frame8} alt='' />
              <img src={frame9} alt='' />
            </div>
          </div>
          <form className='footer-form' onSubmit={handleSubmit}>
            <input type='email' name='email' value={formData.email} onChange={handleFormData} placeholder='Kindly enter your email address' />
            <button disabled={loading} className='btn hero-btn'>
              <NotificationStatus
                size="16"
                color="#FFF"
              />
              {loading ? 'Loading...' : 'Join Waitlist'}
            </button>
          </form>
        </div>
        <div className='footer-socials'>
          <p>© 2022 Stallet Inc. All rights reserved.</p>
          <ul className='footer-links'>
            <li><a href='https://twitter.com/Stalletco'><BsTwitter /></a></li>
            <li><a href='https://linkedIn.com/company/Stalletco'><BsLinkedin /></a></li>
            <li><a href='https://instagram.com/Stalletco'><BsInstagram /></a></li>
            <li><a href='https://facebook.com/Stalletco'><BsFacebook /></a></li>
            <li><a href='https://bit.ly/StalletDiscordChannel'><BsDiscord /></a></li>
            <li><a href='https://medium.com/@Stalletco'><BsMedium /></a></li>
            <li><a href='mailto:hello@stallet.co'><GoMail /></a></li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer