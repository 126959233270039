import React, { useContext, useState } from 'react'
import { NotificationStatus } from 'iconsax-react'
import stackslogo from '../../image/stacks-stx-logo 1.png'
import { useNavigate } from 'react-router-dom'
import DatabaseContext from '../../DatabaseContext'


const Hero = () => {
  const {formData, handleFormData, addEmail, refresh} = useContext(DatabaseContext)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const Navigate = useNavigate()

  
  


  async function handleSubmit(e) {
    e.preventDefault()
    try{
      setError('')
      setLoading(true)
      formData.count++
      await addEmail();
      
      Navigate('/confirmation')
      refresh()
    }
    catch(err){
      setLoading(false)
      setError(`${err}`)
      alert(error)
    }
  }

  

  return (
    <div className='hero'>
      <div className='container hero-container' >
        <div className='hero-details' >
          <div className='hero-texts'>
            <div className='hero-button'><div>Powered by <span><img src={stackslogo} alt='' /></span> Stacks</div></div>
            <h1>Your <span className='hero-light'>all-in-one</span> cross-chain and non-custodial mobile <span className='hero-alt'>crypto wallet</span></h1>
            <div className='hero-paragraph'>
              <p>With Stallet, users can send, receieve, swap and bridge tokens between diffferent networks, manage NFTs and most importantly, learn crypto and web3.</p>
            </div>
          </div>
          <form className='hero-forms' onSubmit={handleSubmit}>
            <input type='email' name='email' value={formData.email} onChange={handleFormData} placeholder='Kindly enter your email address' />
            <button disabled={loading} className='btn hero-btn' >
              <NotificationStatus
                size="16"
                color="#FFF"
              />
              {loading ? 'Loading...' : 'Join Waitlist'}
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Hero