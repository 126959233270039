import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import React, { useEffect, useRef } from 'react'
import image4 from '../../image/IMG_4269-min.gif'

const Web3 = () => {
  var web3 = useRef()
  gsap.registerPlugin(ScrollTrigger)
  useEffect(() => {
    var ctx = gsap.context(() => {
      var mn = gsap.matchMedia()

      mn.add('(max-width: 1024px)', () => {
        gsap.set('.web3-image', { zIndex: '3'})
        ScrollTrigger.create({
          trigger: '.web3-texts',
          start: 'top top',
          pin: true,
          pinSpacing: false,
          scrub: true
        })
        ScrollTrigger.create({
          trigger: '.web3-image',
          start: 'top top',
          pin: true,
          pinSpacing: false,
          scrub: true
        })
      })
    }, web3)

    return () => ctx.revert()
  }, [])

  
  return (
    <div className='web3' ref={web3}>
      <div className='web3-texts'>
        <div className='web3-texts-container'>
          <h2>New to Crypto & Web3? No Worries 👌</h2>
          <div className='web3-paragraph'>
            <p>Stallet Learn has everything you need!</p>

            <p>It is an educational arm of Stallet, that contains numerous information about web3, and helps users learn in a fun and interesting way.</p>

            <p>With the help of Stallet Learn, potential users can easily navigate and learn how to also use Stallet effectively.</p>

            <p>Let’s get you started with learning crypto and web3.</p>
          </div>
        </div>
      </div>
      <div className='web3-image'>
        <img src={image4} alt='' />
      </div>
    </div>
  )
}

export default Web3

