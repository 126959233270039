import { collection, doc, getDocs, setDoc } from 'firebase/firestore'
import React, { createContext,    useState } from 'react'
import { db } from './Firebase'

const DatabaseContext = createContext(null)

export default DatabaseContext

export const DatabaseProvider = ({children}) => {
  const [formData, setFormData] = useState({
    email: '',
    count: 0
  })

  const handleFormData = (e) => {
    const {value, name} = e.target
    setFormData(prevState => {
      return({
        ...prevState,
        [name]: value
      })
    })
  }

  function addEmail() {
    // formData.count++
    const addDocRef = doc(db, 'waitlist', formData.email)
    return setDoc(addDocRef, {...formData})
  }

  function getEmail(){
    return getDocs(collection(db, "waitlist"));
    
  }

  // console.log(formData)

  const refresh = () =>{
    setFormData(prevState => {
      return ({
        ...prevState,
        email: ''
      })
    })
  }

  const value = {
    formData,
    setFormData,
    handleFormData,
    addEmail,
    refresh,
    getEmail
  }

  return(
    <DatabaseContext.Provider value = {value}>
      {children}
    </DatabaseContext.Provider>
  )
}