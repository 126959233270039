import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAS3iZc4X46ET8z5UH9MAVBKfo0Nkv-FvY",
  authDomain: "stallet-689a5.firebaseapp.com",
  projectId: "stallet-689a5",
  storageBucket: "stallet-689a5.appspot.com",
  messagingSenderId: "60018650641",
  appId: "1:60018650641:web:c03b447b2281ef72968bb0"
};

const app = initializeApp(firebaseConfig)


export const db = getFirestore(app);


