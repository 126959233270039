import React, { useEffect, useRef } from 'react'
import Header from './Body/Header'
import Footer from './Body/Footer'
import Main from './Body/Main/Main'
import Confirmation from './Confirmation'
import './fonts/Gilroy-FREE/Gilroy-ExtraBold.otf'
import './styles.css'
import { Route, Routes } from 'react-router-dom'
import { DatabaseProvider } from './DatabaseContext'
import PrivateRoute from './PrivateRoute'
import logo from './image/stalletLogo-alt-removebg-preview.png'
import { gsap, Power3 } from 'gsap'


const Waitlist = () => {
  const waitlist = useRef()
  useEffect(() => {
    var ctx = gsap.context(() => {
      gsap.to('.waitlist-cover > img', {
        duration: 1,
        scale: '3',
        ease: Power3.easeInOut
      })
      gsap.to('.waitlist-cover', {
        duration: 1.5,
        left: '-100%',
        ease: Power3.easeInOut,
        opacity: 0,
        delay: .3
      })

    }, waitlist)

    return () => ctx.revert()
  }, [])

  return (
    <div className='waitlist' ref={waitlist}>
      <div className='waitlist-cover' ><img src={logo} alt='' /></div>
      <Header />
      <Main />
      <Footer />
    </div>
  )
}

const app = () => {
  return (
    <DatabaseProvider>
      <Routes>
        <Route exact path='/' element = {<PrivateRoute><Waitlist /></PrivateRoute>} />
        <Route exact path='/confirmation' element = {<Confirmation />} />
      </Routes>
    </DatabaseProvider>
  )
}

export default app